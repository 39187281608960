<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="650px">
    <v-card>
      <v-card-title class="d-flex justify-content-between h-75px">
        <div>
          <span class="font-weight-medium m-4 text-h5">Generate code</span>
        </div>
        <button @click="toggleModal">
          <span class="svg-icon mr-2">
            <v-icon size="18">mdi-close</v-icon>
          </span>
        </button>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-card class="p-5">
            <v-row>
              <v-col cols="12" lg="10" class="ml-5">
                <FieldContainer
                  v-if="serverData.organizations"
                  :label="$t('organization')"
                  :divider="true"
                >
                  <v-autocomplete
                    v-model="formData.organization_id"
                    clearable
                    dense
                    outlined
                    class="rounded-lg h-50px"
                    required
                    item-text="text"
                    item-value="index"
                    :items="serverData.organizations"
                    :error-messages="organizationsErrors"
                    @blur="$v.formData.organization_id.$touch()"
                    @input="$v.formData.organization_id.$touch()"
                  ></v-autocomplete>
                </FieldContainer>

                <FieldContainer :label="$t('package_type')" :divider="true">
                  <v-autocomplete
                    v-model="formData.package_type_id"
                    clearable
                    dense
                    outlined
                    class="rounded-lg h-50px"
                    required
                    item-text="text"
                    item-value="index"
                    :items="serverData.package_types"
                    :error-messages="packageTypeErrors"
                    @blur="$v.formData.package_type_id.$touch()"
                    @input="$v.formData.package_type_id.$touch()"
                  ></v-autocomplete>
                </FieldContainer>

                <FieldContainer :label="$t('prefix')" :divider="true">
                  <v-text-field
                    dense
                    outlined
                    class="rounded-lg h-50px"
                    clearable
                    v-model="formData.prefix"
                    :error-messages="prefixErrors"
                    @blur="$v.formData.prefix.$touch()"
                    @input="$v.formData.prefix.$touch()"
                  />
                </FieldContainer>

                <FieldContainer :label="$t('no_of_digits')" :divider="true">
                  <v-text-field
                    v-model="formData.no_of_digits"
                    dense
                    class="rounded-lg h-50px"
                    required
                    persistent-hint
                    outlined
                    clearable
                    :error-messages="digitErrors"
                    @change="
                      () => {
                        validateMinValue('no_of_digits', 5, 'formData');
                      }
                    "
                    @blur="$v.formData.no_of_digits.$touch()"
                    @input="$v.formData.no_of_digits.$touch()"
                  ></v-text-field>
                </FieldContainer>

                <FieldContainer :label="$t('package_count')" :divider="false">
                  <v-text-field
                    dense
                    outlined
                    class="rounded-lg h-50px"
                    clearable
                    v-model="formData.package_count"
                    :error-messages="packageCountErrors"
                    @change="
                      () => validateMinValue('package_count', 1, 'formData')
                    "
                    @blur="$v.formData.package_count.$touch()"
                    @input="$v.formData.package_count.$touch()"
                  />
                </FieldContainer>
              </v-col>
            </v-row>

            <v-row class="mt-15">
              <v-col cols="12" lg="11" class="pr-0">
                <v-row class="d-flex justify-content-end">
                  <v-col cols="auto">
                    <button
                      @click="submit"
                      type="submit"
                      class="btn btn-info px-5 py-3 mr-7"
                    >
                      {{ this.$t("submit") }}
                    </button>
                  </v-col>
                </v-row></v-col
              ></v-row
            >
          </v-card>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FieldContainer from "@/own/components/warehouseManagement/packageCode/FieldContainer.vue";
import validationMixin from "@/own/mixins/validationMixin.js";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import SwalService from "@/core/services/swal.service";
import { UPDATE_TABLE_DATA } from "@/core/services/store/packageCode.module.js";

import {
  required,
  numeric,
  requiredIf,
  minLength,
} from "vuelidate/lib/validators";

export default {
  name: "GenerateCodeDialog",
  mixins: [validationMixin, fieldValueValidation],
  components: { FieldContainer },
  validations: {
    formData: {
      organization_id: {
        required: requiredIf(function () {
          return !!this.serverData.organizations;
        }),
      },
      package_type_id: { required },
      prefix: { required, minLength: minLength(2) },
      no_of_digits: {
        required,
        numeric,
      },
      package_count: { required, numeric },
    },
  },
  data() {
    return {
      formData: {
        organization_id: null,
        package_type_id: null,
        prefix: null,
        no_of_digits: null,
        package_count: null,
      },
      dialog: false,
      serverData: {
        organizations: [],
        package_types: [],
      },
    };
  },
  methods: {
    async toggleModal() {
      if (!this.dialog) {
        await this.loadServerData();
      }
      this.dialog = !this.dialog;
    },
    loadServerData() {
      this.$store.commit(SET_PAGE_LOADING, true);
      return ApiService.post(
        "/warehouse_management/custom/package_codes/get-package-generation-data"
      )
        .then((response) => {
          this.serverData = response.data;
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store.commit(SET_PAGE_LOADING, true);

      return ApiService.post(
        "/warehouse_management/custom/package_codes/generate",
        this.formData
      )
        .then(() => {
          SwalService.successMessage({
            title: this.$t(this.$t("created")),
            html: this.$t("created_successfully"),
          });
        })
        .then(() => {
          return this.$store.dispatch(UPDATE_TABLE_DATA, {});
        })
        .then(() => {
          this.toggleModal();
          this.clearFields();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    clearFields() {
      this.formData = {
        organization_id: null,
        package_type_id: null,
        prefix: null,
        no_of_digits: null,
        package_count: null,
      };
    },
  },
  computed: {
    organizationsErrors() {
      return this.handleFormValidation("organization_id", this, "formData");
    },
    packageTypeErrors() {
      return this.handleFormValidation("package_type_id", this);
    },
    prefixErrors() {
      return this.handleFormValidation("prefix", this, "formData", {
        minLength: 2,
      });
    },
    digitErrors() {
      return this.handleFormValidation("no_of_digits", this);
    },
    packageCountErrors() {
      return this.handleFormValidation("package_count", this);
    },
  },
};
</script>
